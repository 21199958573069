import React, { useState } from "react";
import "./Apply.css";
import axios from "axios";
import Api from "../../Api/Api";
import img from "../../Images/apply.png";
import emailjs from "@emailjs/browser";

export default function Apply() {
  const [name, setName] = useState("");
  const [fullname, setFullanme] = useState("");
  const [address, setAddress] = useState("");
  const [position, setPosition] = useState("");
  const [from, setEmail] = useState("");
  const [msgs, setMsg] = useState("");
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePosition = (e) => {
    setPosition(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleMsg = (e) => {
    setMsg(e.target.value);
  };
  const handleOnChangeFiles = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };
  function submitEmail() {
    const formData = new FormData();
    formData.set("image", file);
    formData.set("name", name);
    formData.set("from", from);
    formData.set("msg", msgs);
    formData.set("position", position);
    formData.set("address", address);
    // let datax = {
    //   name: name,
    //   from: from,
    //   msg: msgs,
    //   position: position,
    //   address: address,
    // };

    console.log(formData);

    Api.upload(formData).then((res) => {
      console.log(res.data);
      if (res.data == "success") {
        window.alert("Mail sent Successfully");
        window.location.reload();
      } else {
        window.alert("Mail Failed");
      }
      // setName("");
      // setEmail("");
      // setMsg("");
    });
  }
  // function submitEmail() {

  //    const formData = new FormData();
  //    formData.append("avatar", file);
  //   let datax = {
  //     name: name,
  //     from: from,
  //     msg: msgs,
  //     position: position,
  //     address: address,
  //   };

  //   // console.log("datax", datax);

  //   // emailjs
  //   //   .send("<YOUR_SERVICE_ID>", "<YOUR_TEMPLATE_ID>", templateParams)
  //   //   .then(
  //   //     function (response) {
  //   //       console.log("SUCCESS!", response.status, response.text);
  //   //     },
  //   //     function (err) {
  //   //       console.log("FAILED...", err);
  //   //     }
  //   //   );
  //   Api.sendCV(datax).then((res) => {
  //     // console.log(res.data);
  //     if (res.data == "success") {
  //       window.alert("Mail sent Successfully");
  //     } else {
  //       window.alert("Mail Failed");
  //     }
  //     setName("");
  //     setEmail("");
  //     setMsg("");
  //   });
  // }
  return (
    <div className="container cont">
      <div className="container justify-content-center align-items-center ff">
        <div className="form">
          <h1 className="title text-center mb-4">Apply Now</h1>
          <div className="form-group position-relative">
            <label for="formName" className="d-block">
              <i className="icon" data-feather="user"></i>
            </label>
            <input
              value={name}
              type="text"
              id="formName"
              className="form-control form-control-lg thick"
              placeholder="Full Name"
              onChange={handleChange}
            />
          </div>
          <div className="form-group position-relative">
            <label for="formEmail" className="d-block">
              <i className="icon" data-feather="mail"></i>
            </label>
            <input
              value={from}
              type="email"
              id="formEmail"
              className="form-control form-control-lg thick"
              placeholder="E-mail"
              onChange={handleEmail}
            />
          </div>
          <div className="form-group position-relative">
            <label for="formName" className="d-block">
              <i className="icon" data-feather="user"></i>
            </label>
            <input
              value={address}
              type="text"
              id="formName"
              className="form-control form-control-lg thick"
              placeholder="Address"
              onChange={handleAddress}
            />
          </div>
          <div className="form-group position-relative">
            <label for="formName" className="d-block">
              <i className="icon" data-feather="user"></i>
            </label>
            <input
              value={position}
              type="text"
              id="formName"
              className="form-control form-control-lg thick"
              placeholder="Position"
              onChange={handlePosition}
            />
          </div>
          Choose Your Cv
          <div className="form-group position-relative">
            <label for="formName" className="d-block">
              <i className="icon" data-feather="user"></i>
            </label>
            <input
              // value={name}
              type="file"
              id="formName"
              className="form-control form-control-lg thick"
              placeholder="Position"
              onChange={handleOnChangeFiles}
              // onChange={handleChange}
            />
          </div>
          <div className="form-group message">
            <textarea
              value={msgs}
              id="formMessage"
              className="form-control form-control-lg"
              rows="7"
              placeholder="Cover Letter"
              onChange={handleMsg}
            ></textarea>
          </div>
          <div className="text-center">
            <button
              onClick={() => submitEmail()}
              type="submit"
              className="btn btn-primary"
              tabIndex="-1"
              style={{ background: "#2d438e" }}
            >
              Send Cv
            </button>
          </div>
        </div>
        <img style={{ margin: "30px" }} src={img} />
      </div>

      {/* <div>
        <div className="direction">
          <ul>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                fill="currentColor"
                class="bi bi-telephone"
                viewBox="0 0 16 16"
              >
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>

              <h2>CALL US</h2>
              <h5>+971 56 914 7880</h5>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                fill="currentColor"
                class="bi bi-envelope-plus"
                viewBox="0 0 16 16"
              >
                <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z" />
              </svg>
              <h2>MAIL US</h2>
              <h5>smartbox@smartbox-brokerservices.com</h5>
            </li>
            <li>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3630.0105894454887!2d54.3683313153543!3d24.51971596486729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e66e0ffffffff%3A0x4de05558d748c26d!2sFoodco%20Holding!5e0!3m2!1sen!2sae!4v1655023480146!5m2!1sen!2sae"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
}
