import "./slider.css";
export default function Slider() {
  const images = [
    "http://www.jackanddiana.com/wp-content/uploads/2014/02/neigh-changing.jpg",
    "https://wallpaperaccess.com/full/7168014.jpg",
  ];
  return (
    <div className="App">
      <div className="b1 slide1">
        <img
          className="imds"
          src="https://github.com/Sacsam005/image-slider-SASS/blob/master/img/img1.jpg?raw=true"
          alt=""
        />
        <div className="text-box zoom1">
          <h1>Nepal</h1>
        </div>
      </div>
      <div className="b2 slide2">
    

        <div className="text-box zoom2">
          <h1>United States</h1>
        </div>
      </div>
    </div>
  );
}
