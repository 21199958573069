import React from "react";
import "./About.css";

export default function About() {
  return (
    <div className="dx" style={{ marginTop: "20vh" }}>
      <section class="text-center about container">
        <div className=" " align="center">
          <h1 data-aos="zoom-out-right " className="mserv" align="center">
            ABOUT <label style={{ color: "#df2528" }}>US</label>{" "}
          </h1>
        </div>
        <p>
          Smart Box specializes in the delivery and commercial brokerage
          <br />
          services (Real Estate and Human Resources) for small and large
          enterprises alike. <br />
          What sets us apart is that we are well informed, <br />
          passionate, and lively and we strive hard to meet your needs. <br />
          <br />
        </p>

        <br />

        <div>
          <div align=" ">
            <h4 style={{ fontWeight: "bold" }}>VALUE</h4>
          </div>

          <ul align="" style={{ listStyle: "none" }}>
            <li>
              <label style={{ fontWeight: "bold", color: "#e02629" }}>
                {" "}
                Well-Informed.
              </label>{" "}
              <br />
              We make every effort to comprehend our markets as well as our
              customers' demands.
            </li>
            <li>
              {" "}
              <label style={{ fontWeight: "bold", color: "#e02629" }}>
                Attached.
              </label>{" "}
              <br />
              Relationships are everything to us; we connect to the communities.
            </li>
            <li>
              <label style={{ fontWeight: "bold", color: "#e02629" }}>
                {" "}
                Passionate.
              </label>{" "}
              <br />
              We believe that working with all hearts can transform the globe.
            </li>
            <li>
              {" "}
              <label style={{ fontWeight: "bold", color: "#e02629" }}>
                Lively.
              </label>{" "}
              <br />
              We like what we do and it reveals.
            </li>
            <li>
              <label style={{ fontWeight: "bold", color: "#e02629" }}>
                Upstanding.
              </label>{" "}
              <br />
              Our customers' requirements, as well as benefits.are at the heart
              of whatever we do.
            </li>
            <li>
              <label style={{ fontWeight: "bold", color: "#e02629" }}>
                {" "}
                Efficient.{" "}
              </label>
              <br />
              We set a high bar and also relocate mountains to surpass
              expectations
            </li>
          </ul>
        </div>
        <br />
        <div class="container">
          <div class="row">
            <div class="col  about-item wow lightSpeedIn" data-wow-offset="200">
              <span class="fa fa-info" style={{ color: "#e02629" }}></span>
              <br />
              <h2>MISSION</h2>
              <p class=" ">
                We strive to understand our clients' needs in order
                <br /> to provide on-time first-class services with reasonable
                and consistent rates.
              </p>
            </div>

            <div class="col about-item wow lightSpeedIn" data-wow-offset="200">
              <span class="fa fa-file" style={{ color: "#e02629" }}></span>
              <br />
              <h2>VISION</h2>
              <p class=" ">
                Connecting the dots and creating a comprehensive <br /> delivery
                and commercial brokerage service worldwide.
              </p>
            </div>
          </div>
        </div>

        <br />
        <br />

        <br />
        <br />
      </section>
    </div>
  );
}
