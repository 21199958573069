import React from "react";

export default function Partner() {
  return (
    <div style={{ background: "white" }}>
      <div className="container">
        <div data-aos="zoom-out-right" align="center">
          <div className="MainX">
            <br />
            <br />
            <h1 style={{ fontWeight: "bold", fontSize: "46px" }}>
              Our <span style={{ color: "#df2528" }}>Patners</span>
            </h1>
            <div>
              <img
                src="https://www.speedexpresstravel.com/static/media/Banner.127a5396d3d43440b92d825a933baddb.svg"
                width="30%"
              />
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
