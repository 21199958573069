import React, { useState } from "react";
import "./Header.css";
import Logo from "../../../Images/Icon.png";
import { Link } from "react-router-dom";
import { NavBar, Nav, NavItem } from "react-bootstrap";

export default function Header() {
  const [active, setActive] = useState(0);
  return (
    <div className="hd" style={{ background: "white" }}>
      <div className="container">
        <Nav className="navbar sticky-top navbar-expand-lg fx">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={Logo} className="logohd" />
              {/* <Link to="/Delivery" style={{ textDecoration: "none" }}>

              <img src={Logo}  width="50%" />
            </Link> */}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-list-nested"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto w-100 justify-content-end bg">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <li
                    className="nav-item active"
                    onClick={() => setActive(0)}
                    style={
                      active == "0"
                        ? {
                            borderBottom: "3px solid #df2528 ",
                            color: "#2d438e",
                            fontWeight: "bold",
                          }
                        : { borderBottom: "none ", color: "#2d438e" }
                    }
                  >
                    <label style={{ fontWeight: " ", color: "#2d438e" }}>
                      Home
                    </label>
                  </li>
                </Link>

                {/* <li className="nav-item ">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <label style={{ fontWeight: "" }}>Why Smartbox</label>
                </Link>
              </li> */}
                <Link
                  to="/Services"
                  style={{ textDecoration: "none", color: "#2d438e" }}
                  onClick={() => setActive(1)}
                >
                  <li
                    className="nav-item "
                    style={
                      active == "1"
                        ? {
                            borderBottom: "3px solid #df2528 ",
                            fontWeight: "bold",
                          }
                        : { borderBottom: "none" }
                    }
                  >
                    <label style={{ fontWeight: "", color: "#2d438e" }}>
                      Our Services
                    </label>
                  </li>
                </Link>
                <Link
                  to="/About"
                  style={{ textDecoration: "none" }}
                  onClick={() => setActive(4)}
                >
                  <li
                    className="nav-item "
                    style={
                      active == "4"
                        ? {
                            borderBottom: "3px solid #df2528",
                            fontWeight: "bold",
                          }
                        : { borderBottom: "none" }
                    }
                  >
                    <label style={{ fontWeight: "", color: "#2d438e" }}>
                      About Us
                    </label>
                  </li>
                </Link>
                <Link
                  to="/Contact"
                  style={{ textDecoration: "none" }}
                  onClick={() => setActive(2)}
                >
                  <li
                    className="nav-item "
                    style={
                      active == "2"
                        ? {
                            borderBottom: "3px solid #df2528 ",
                            fontWeight: "bold",
                          }
                        : { borderBottom: "none " }
                    }
                  >
                    <label
                      style={{
                        fontWeight: "",
                        color: "#2d438e",
                        fontWeight: "",
                      }}
                    >
                      Contact Us
                    </label>
                  </li>
                </Link>
                <Link
                  to="/Apply"
                  style={{ textDecoration: "none" }}
                  onClick={() => setActive(5)}
                >
                  <li
                    className="nav-item "
                    style={
                      active == "5"
                        ? {
                            borderBottom: "3px solid #df2528 ",
                            fontWeight: "bold",
                          }
                        : { borderBottom: "none " }
                    }
                  >
                    <label
                      style={{
                        fontWeight: "",
                        color: "#2d438e",
                        fontWeight: "",
                      }}
                    >
                      Job Apply
                    </label>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </Nav>

        {/* <div class="header" align="" >
        <label class="logo">
          <Link to="/Delivery" style={{ textDecoration: "none" }}>
            <img src={Logo}   className="Logo" />
          </Link>
        </label>
        <div class="header-right">
          <Link to="/" style={{ textDecoration: "none" }}>
            <label style={{ fontWeight: "bold" }}>Services</label>
          </Link>
          <Link to="/Delivery" style={{ textDecoration: "none" }}>
            <label>Deilvery</label>
          </Link>
          <Link to="/Delivery" style={{ textDecoration: "none" }}>
            <label>Real Estate</label>
          </Link>

          <label
            class="bixbutton hd"
            href="#home"
            style={{
              color: "white",
              background: "#f4b6b7",
              fontWeight: "bold",
            }}
          >
            Contact Us
          </label>
        </div>
      </div> */}
      </div>
    </div>
  );
}
