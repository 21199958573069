import axios from "axios";
// import dx from "../module"
// const USER_API_URL = "http://localhost/payment";
// const USER_API_URL = "../module";
// const USER_API_URL = "https://accounts.speedexpresstravel.com/module2";
const USER_API_URL = "https://smartbox-brokerservices.com";
// const USER_API_URL = "http://localhost/sendMail";

class Api {
  sendmail(user) {
    console.log("User", user);
    return axios.post(`${USER_API_URL}/mail.php`, user);
  }
  sendCV(user) {
    console.log("User", user);
    return axios.post(`${USER_API_URL}/mailCV.php`, user);
  }
  upload(user) {
    console.log("User", user);
    return axios.post(`${USER_API_URL}/upload.php`, user);
  }
}

export default new Api();
