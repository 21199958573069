import React from "react";
import { Link } from "react-router-dom";
import "./Main.css";

export default function Main() {
  return (
    <div className=" ">
      <div className="container">
        <div className="Main">
          <div className="daa" style={{ marginTop: "25vh" }}>
            <h1 data-aos="zoom-out-right">
              Perfect {"  "}
              <span style={{ color: "#df2528" }}>
                Delivery & <br />
                Brokerage Services
              </span>
            </h1>
            <p data-aos="zoom-out-right">
              Smart Box specializes in the delivery and commercial brokerage
              services (Real Estate and Human Resources) for small and large
              enterprises alike. What sets us apart is that we are well
              informed, passionate, and lively and we strive hard to meet your
              needs.
            </p>
            <br />
            <br />
            <br />
            <Link to="/Services">
              <button
                className="btn btn-primary bixbutton"
                data-aos="zoom-out-right"
              >
                View Our Services
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
