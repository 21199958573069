import React from "react";
import "./Services.css";
import Deli from "../../../Images/Svg/Deli.svg";
import Real from "../../../Images/Svg/Real.png";
import Man from "../../../Images/Svg/Man.svg";
import MIS from "../../../Images/mission.svg";

import { Link } from "react-router-dom";
import Partner from "../Partner/Partner";

export default function Services() {
  return (
    <div className=" ">
      <div className="floatHd">
        <span>MISSION</span>
      </div>
      <div style={{ background: "", padding: "20px" }} className="msbg">
        <div className="container">
          <div data-aos="zoom-out-right" align="center">
            <div className="MainX">
              <br />
              <br />
              <br />
              <h1 style={{ fontWeight: "bold", fontSize: "46px" }}>
                Our <span style={{ color: "#df2528" }}>Mission</span>
              </h1>
            </div>

            <div className="row " style={{ width: "70%", margin: "auto" }}>
              <div className="col" align="left">
                <p>
                  We strive to understand our clients’ needs in order to provide
                  on-time first-class services with reasonable and consistent
                  rates
                </p>
                <p>
                  Creating a truly unique experience for each client is what we
                  do best.
                  <br /> We are customized to each client’s needs, reflecting
                  their brand personality, thus creating magic in the community.
                </p>
              </div>
              <div className="col mimg">
                <img src={MIS} width="100%" />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
      <div className=" ">
        <div className="">
          <div className="MainX container">
            <br />
            <br />
            <br />
            <div
              style={{ width: "100%" }}
              align="center"
              data-aos="zoom-out-right"
            >
              <div className="floatHd" id="services">
                <span>SERVICES</span>
              </div>
              <h1 style={{ fontWeight: "bold", fontSize: "46px" }}>
                Our <span style={{ color: "#df2528" }}>Services</span>
              </h1>

              <p>
                As the leading provider of delivery and broker services,
                <br /> Smart Box is committed to exceeding your expectations
                through efficiency,
                <br />
                knowledge, passion, and experience. Our systematic and organized
                <br />
                approach combines your big ideas with our professionalism and
                adds
                <br />a generation of effectiveness to your project.
              </p>
              <br />
            </div>
            <div className="Services container">
              <ul>
                <li data-aos="zoom-out-right">
                  <img src={Deli} width="90%" />
                  <h2 style={{ color: "red" }}>DELIVERY SERVICE</h2>
                  <p>
                    With our services, both people and organizations may easily
                    move goods from start to end. We have on-demand and tailored
                    delivery services in Abu Dhabi and the UAE for businesses
                    such as retail, food, and beverage, as well as
                    pharmaceuticals and more.
                  </p>
                  <br />
                </li>{" "}
                <li data-aos="zoom-out-right">
                  <img src={Man} width="90%" />
                  <h2 style={{ color: "red" }}>MAN POWER</h2>
                  <p>
                    Smart Box Broker Services employment specialists develop
                    effective partnerships with clients, offering a one-stop
                    option for all their hiring demands and also saving them
                    useful time while hiring the appropriate prospects.
                  </p>
                  <br />
                </li>{" "}
                <li data-aos="zoom-out-right">
                  <img src={Real} width="90%" />
                  <h2 style={{ color: "red" }}>REAL ESTATE</h2>
                  <p>
                    Being one of the most energetic realty brokers in Abu Dhabi,
                    Smart Box Broker Services preserves an open line of
                    interaction that is based upon a dynamic vendor/agent
                    connection, enabling us to communicate between all relevant
                    events.
                  </p>
                  <br />
                </li>
              </ul>
              <div align="center">
                <Link to="/" style={{ textDecoration: "none" }}>
                  {" "}
                  <p className="view">Find more &rarr;</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
