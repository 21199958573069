import React from "react";
import "./Estate.css";

import Subscribe from "../../Parts/Subscribe/Subscribe";

import d1 from "../Estate/img/e1.svg";
import d2 from "../Estate/img/truck.png";
import d3 from "../Estate/img/e3.svg";

import r1 from "../Estate/img/r1.svg";
import r2 from "../Estate/img/r2.svg";
import r3 from "../Estate/img/r3.svg";

import m1 from "../Estate/img/m1.svg";
import m2 from "../Estate/img/m2.svg";
import m3 from "../Estate/img/m3.svg";
import Slider from "./Slider";
import { Link } from "react-router-dom";

export default function Estate() {
  return (
    <div className="Ebg" style={{ background: "" }}>
      <div style={{ background: "" }}>
        <div className="container">
          <div className="eMain" style={{ height: "90vh" }}></div>
        </div>
      </div>

      <div>
        <div className="b1 slide1 ">
          <div className="sl1 tt bg1x"></div>
          <div className="text-box  ">
            <div className="container">
              <div className="xx ">
                <p
                  className="mserv"
                  style={{ fontWeight: "bold", fontSize: "44px" }}
                >
                  OUR <span style={{ color: "#df2528" }}> SERVICES</span>{" "}
                </p>
                <p data-aos="zoom-out-right">
                  As the leading provider of delivery and broker services, Smart
                  Box is committed to exceeding your expectations through
                  efficiency, knowledge, passion, and experience. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="b2 slide2">
          <div className="sl1 tt bg2"></div>
          <div className="text-box zoom2">
            <div className="container">
              <div className="xx">
                <p
                  className="mserv"
                  style={{
                    fontWeight: "bold",
                    fontSize: "44px",
                    color: "lightgrey",
                  }}
                >
                  OUR{" "}
                  <span style={{ color: "#df2528" }}>DELIVERY SERVICES</span>{" "}
                </p>
                <p data-aos="zoom-out-right" style={{ color: "white" }}>
                  Being one of the most energetic realty brokers in Abu Dhabi,
                  Smart Box Broker Services preserves an open line of
                  interaction that is based upon a dynamic vendor/agent
                  connection, enabling us to communicate between all relevant
                  events and also negotiate the very best feasible value for
                  your assets.
                </p>
                {/* <Link to="/#deli">
                  <button className="btn btn-primary bixbutton">Explore</button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="b2 slide3">
          <div className="sl1 tt bg3" style={{ background: "" }}></div>
          <div className="text-box zoom2">
            <div className="container">
              <div
                className="xx"
                style={{
                  borderRadius: "8px",
                  background: "white",
                  padding: "20px",
                  float: "right",
                }}
              >
                <p
                  className="mserv"
                  style={{ fontWeight: "bold", fontSize: "44px" }}
                >
                  REAL ESTATE{" "}
                  <span style={{ color: "#df2528" }}> SERVICES</span>{" "}
                </p>
                <p data-aos="zoom-out-right">
                  Being one of the most energetic realty brokers in Abu Dhabi,
                  Smart Box Broker Services preserves an open line of
                  interaction that is based upon a dynamic vendor/agent
                  connection, enabling us to communicate between all relevant
                  events and also negotiate the very best feasible value for
                  your assets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row datapad" align="center">
          <div className="MainX">
            <br />
            <br />
            <h1 id=" ">
              <span
                style={{
                  color: "#df2528",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Delivery Services
              </span>
            </h1>
          </div>
          <div></div>
          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <img src={d1} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">ECOMMERCE</h3>
              To meet the needs of online retailers and social media businesses,
              we offer a vast distribution network, a partnership with large
              local retailers, and a concierge service. Delivering reliable,
              prompt, and affordable courier services throughout the UAE is our
              goal..
            </div>
          </div>

          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={d2} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">CASH ON DELIVERY (COD)</h3>
              <br />
              Using Cash on Delivery (COD), businesses can deliver products and
              services to their customers and collect money when they arrive.
              You can secure fees from your customers with our COD, customized
              to suit your specific needs.
            </div>
          </div>
          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={d3} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">RESTAURANT & SUPERMARKET</h3>
              <br />
              Our food and grocery delivery service are hygienic and timely. And
              with our live order tracking for you and your customer, we promise
              your delivery arrives fresh and safely.
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="row datapad" align="center">
          <div className="MainX">
            <h1>
              <span
                style={{
                  color: "#df2528",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Man power Supply
              </span>
            </h1>
          </div>

          <div className="col" id="deli" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={m1} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">EMIRATISATION</h3>
              <br />
              Smart Box Broker Services deals with properties for sale and rent
              in Abu Dhabi developing a strong bridge between owners and buyers
              or occupants. With the help of a highly competent team, Smart Box
              Broker Services link various events to develop a longlasting
              partnership with the most fruitful outcomes, and also more crucial
              than that is to meet the client's assumptions.
            </div>
          </div>

          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={m2} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">EXECUTIVE SEARCH</h3>
              <br />
              Finding a needle in a haystack is an exhaustive, as well as
              experienced task, taken care of only by expert search working as a
              consultant. Our search professionals use several methods to
              headhunt scarcely and also much-desired abilities. These consist
              of marketing research for industry-specific profiles, sales call,
              go across referencing, shoulder touching, networking, database
              search, and also even headhunting.
            </div>
          </div>
          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={m3} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">OUT SOURCING</h3>
              <br />
              Employee Outsourcing remains to get traction in the region.
              Employees are employed on Smart Box Services visa as well as
              pay-roll and then released to consumer's location for momentary or
              long-term work which allows the customers to focus on their core
              company capabilities, conserve expenses and also expand swiftly.
              Be it at the incubation phase or growth, contracting out provides
              the versatility as well as scalability to expand service
              operations.
            </div>
          </div>
        </div>
        <br />
        <br />
        <br /> <br />
        <br />
        <br />
        <div className="row datapad" align="center">
          <div className="MainX">
            <h1>
              <span
                style={{
                  color: "#df2528",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Real Estate Services
              </span>
            </h1>
          </div>

          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={r1} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">PROPERTY BROKERAGE</h3>
              <br />
              Smart Box Broker Services deals with properties for sale and rent
              in Abu Dhabi developing a strong bridge between owners and buyers
              or occupants. With the help of a highly competent team, Smart Box
              Broker Services link various events to develop a longlasting
              partnership with the most fruitful outcomes, and also more crucial
              than that is to meet the client's assumptions.
            </div>
          </div>

          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={r2} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">PROPERTY MANAGEMENT</h3>
              <br />
              No matter where you reside, whether in the country or abroad,
              Smart Box Broker Services will certainly care for your residential
              property and provide you total comfort, we go an added mile and
              manage the Snagging & Handover in your place
            </div>
          </div>
          <div className="col" data-aos="zoom-out-right">
            <div className="bx1">
              <br />
              <br />
              <br />
              <img src={r3} width="50%" data-aos="zoom-out-right" />
              <h3 data-aos="zoom-out-right">PROPERTY INVESTMENT</h3>
              <br />
              Smart Box Broker Services is in the heart of the buildings market
              in Abu Dhabi in sense of being revealed to a huge number of
              transactions daily and also, they would certainly have the
              understanding you are looking for to evaluate your property in Abu
              Dhabi.
            </div>
          </div>
        </div>
        <br /> <br />
        <br />
        <br />
      </div>
      <Subscribe />
      <div></div>
    </div>
  );
}
