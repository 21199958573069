import React from "react";
import "./Delivery.css";
import Del from "../../../Images/Delivery/Track.png";
import iMG from "../../../Images/data.svg";
import Header from "../../Parts/Header/Header";
import Logo from "../../../Images/Icon.png";
import ECO from "../../../Images/Svg/E11.png";
import Tru from "../../../Images/Svg/truck.png";
import { Link } from "react-router-dom";

export default function Delivery() {
  return (
    <div className="dbg" style={{ background: "" }}>
      
      <div className="dataDX">
        <div className="container mainDl">
          <div className="row">
            <div className="col-4">
              <br />

              <div style={{ color: " " }}>
                <h1 className="dhd">GET PERFECT</h1>

                <p>
                  We have on-demand and tailored delivery services in Abu Dhabi
                  and the UAE for businesses such as retail, food, and beverage,
                  as well as pharmaceuticals and more
                </p>
                <br />
                <br />
                <button
                  className="btn btn-success"
                  style={{
                    color: "white",
                    background: "darkblue",
                    fontWeight: "bold",
                    border: "none",
                  }}
                >
                  Contact us
                </button>
              </div>
            </div>
            <div className="col" align="center">
              <img src={Tru} width="100%" />
            </div>
          </div>
        </div>
      </div>
      <div className="floatHd">
        <span>DELIVERY</span>
      </div>
      <div align="center" className="exp">
        <h1 data-aos="zoom-out-right">EXPLORE OUR DELIVERY SERVICES</h1>
        <p className="NrmF" data-aos="zoom-out-right">
          With our services, both people and organizations
          <br /> may easily move goods from start to end.
        </p>
      </div>

      <div className="container">
        <div className="Desc">
          <div className="row">
            <div className="col" align="center">
              <br />
              <br />
              <img src={ECO} data-aos="zoom-out-right" width="100%" />
            </div>
            <div className="col" style={{}}>
              <div className="inWid">
                <div data-aos="zoom-out-right" className="dNum">
                  1
                </div>
                <h4>E-COMMERCE</h4>
                <p data-aos="zoom-out-right">
                  To meet the needs of online retailers and social media
                  businesses, we offer a vast distribution network, a
                  partnership with large local retailers, and a concierge
                  service. Delivering reliable, prompt, and affordable courier
                  services throughout the UAE is our goal..
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col" style={{}}>
              <div className="inWid">
                <div className="dNum" data-aos="zoom-out-right">
                  2
                </div>
                <h4>CASH ON DELIVERY (COD)</h4>
                <p data-aos="zoom-out-right">
                  Using Cash on Delivery (COD), businesses can deliver products
                  and services to their customers and collect money when they
                  arrive. You can secure fees from your customers with our COD,
                  customized to suit your specific needs.
                </p>
              </div>
            </div>
            <div className="col" align="center">
              <img src={Del} data-aos="zoom-out-right" />
            </div>
          </div>
          <div className="row">
            <div className="col" align="center">
              <img src={Del} data-aos="zoom-out-right" />
            </div>
            <div className="col" style={{}}>
              <div className="dNum" data-aos="zoom-out-right">
                3
              </div>
              <div className="inWid">
                <h4>RESTAURANT & SUPERMAKERT</h4>
                <p data-aos="zoom-out-right">
                  Our food and grocery delivery service are hygienic and timely.
                  And with our live order tracking for you and your customer, we
                  promise your delivery arrives fresh and safely. .
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div>
        <div className="container">
          <div
            className="sub"
            data-aos="zoom-out-right"
            style={{ marginTop: "0px" }}
          >
            <h1>Let's Work Together</h1>
            <p>
              <u>Mission</u> <br />
              We strive to understand our clients’ needs in order to provide{" "}
              <br />
              on-time first-class services with reasonable and consistent rates.
            </p>

            <div>
              <input placeholder="Email" />
              <input placeholder="Message" />
              <button className="btn btn-sucess">SEND</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
