import React from "react";
import "./Sub.css";
import Email from "../../../Images/Svg/EM.png";

export default function Subscribe() {
  return (
    <div>
      <div className="subdatdd" style={{background:""}}>
         <div className="sub" data-aos="zoom-out-right">
          <div className="dx">
            <div align="right" className="Fla">
              <img src={Email} />
            </div>
            <h1>Let's Work Together</h1>
            <p>
              <u>Mission</u> <br />
              We strive to understand our clients’ needs in order to provide{" "}
              <br />
              on-time first-class services with reasonable and consistent rates.
            </p>

            <div>
              <input placeholder="Email" />
              <input placeholder="Message" />
              <button className="btn btn-sucess">SEND</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
