import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Components/Parts/Header/Header";
import "./App.css";
import Main from "./Components/Parts/Main/Main";
import Services from "./Components/Parts/Services/Services";
import Subscribe from "./Components/Parts/Subscribe/Subscribe";
import Footer from "./Components/Parts/Footer/Footer";
import { useEffect } from "react";
import Delivery from "./Components/Pages/Delivery/Delivery";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import Estate from "./Components/Pages/Estate/Estate";
import Contact from "./Components/Contact/contact";
import Partner from "./Components/Parts/Partner/Partner";
import About from "./Components/Pages/AboutUs/About";
import Slider from "./Components/Pages/Estate/Slider";
import Apply from "./Components/Apply/Apply";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <div className="">
        <Header />
        <Switch>
          <Route exact path="/">
            <div className="MainHd">
              <Main />
            </div>
            <Services />
            <Partner />
            <Subscribe />
          </Route>
          <Route path="/Delivery">
            <Delivery />
          </Route>
          <Route path="/Contact">
            <Contact />
          </Route>
          <Route path="/Services">
            <Estate />
          </Route>
          <Route path="/About">
            <About />
          </Route>
          <Route path="/Apply">
            <Apply />
          </Route>

          <Route path="/s">
            <Slider />
          </Route>
        </Switch>
        <div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
